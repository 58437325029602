<template>
  <div
    ref="dockRef"
    class="flex transition-all gap-4"
    :class="[
        withBackground ? 'supports-backdrop-blur:bg-white/10 supports-backdrop-blur:dark:bg-gray-900/10 mx-auto rounded-2xl border backdrop-blur-md' : ' px-8 -mx-8',
        $props.class,
        dockClass,
    ]
    "
    @mousemove="onMouseMove"
    @mouseleave="onMouseLeave"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">

const props = withDefaults(
  defineProps<{
    class?: string;
    magnification?: number;
    distance?: number;
    baseIconWidth?: number;
    direction?: string;
    withBackground?: boolean;
  }>(),
  {
    class:"",
    magnification: 60,
    distance: 140,
    baseIconWidth: 20,
    direction: "middle",
    withBackground: false,
  }
);

const dockRef = ref<HTMLElement | null>(null);
const mouseX = ref(Infinity);

const dockClass = computed(() => ({
  "items-start": props.direction === "top",
  "items-center": props.direction === "middle",
  "items-end": props.direction === "bottom",
}));

const onMouseMove = (e: MouseEvent) => {
  requestAnimationFrame(() => {
    mouseX.value = e.pageX;
  });
};

const onMouseLeave = () => {
  mouseX.value = Infinity;
};

provide("mouseX", mouseX);
provide("magnification", props.magnification);
provide("distance", props.distance);
provide("baseIconWidth", props.baseIconWidth);

</script>