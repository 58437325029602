<template>
  <Dock direction="middle" :magnification="magnification" :distance="distance" :base-icon-width="baseIconWidth" class="h-12 w-full" :class="socialShare.color.icon[color]">
    <component :is="dockComponent" v-for="item in list" :text="`deel ${mode === 'profile' ? 'profiel' : 'resultaten'} via ` + item.label">
      <DockIcon :name="item.icon" @click="openShareDialog(item.key)" />
    </component>
    
  </Dock>
  <LazyUModal v-model="isOpen" :fullscreen="!grid.sm">
    <UForm :state="state" :validate="validate" @submit="send" class="h-full">
      <IconCard
        v-if="currentItem"
        :icon="currentItem.icon" 
        :color="color"
        :ui="{ 
          base: 'sm:max-h-[90dvh] h-full',
          ring: '', 
          rounded: 'rounded-none sm:rounded-lg',
          divide: 'divide-y divide-gray-100 dark:divide-gray-700',
          shadow: 'shadow-none',
          body: {
            base: 'overflow-y-scroll'
          }
        }"
      >
        <template #header>
          <div class="flex items-start gap-2 justify-between w-full">
            <h4 class="mb-0 mt-1 sm:mt-1.5 text-gray-600 dark:text-gray-400">Deel {{ mode === 'profile' ? 'profiel' : 'resultaten' }} via {{currentItem.label}}</h4>
            <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" size="lg" class="shrink-0" @click="isOpen = false" />
          </div>
        </template>

        <div class="flex flex-col gap-6">
          <UFormGroup
            name="email"
            v-if="currentDialogKey === 'email'"
            :ui="{
              description: 'mb-2'
            }"
            :description="emailDescription"
          >
            <UInput v-model="state.emailAddress" :placeholder="emailPlaceholder" />
          </UFormGroup>
          <UFormGroup
            name="subject"
            v-if="currentDialogKey === 'email'"
            :ui="{
              description: 'mb-2'
            }"
            :description="emailSubjectDescription"
          >
            <UInput v-model="state.subject" :placeholder="emailSubjectPlaceholder" />
          </UFormGroup>

          <UFormGroup
            name="message"
            :ui="{
              description: 'mb-2'
            }"
            :description="messageDescription"
          >
            <UTextarea v-model="state.message" autoresize :placeholder="messagePlaceholder" :rows="5" />
          </UFormGroup>

        </div>
        

        <template #footer>
          <div class="flex justify-end">
            <UButtonGroup size="lg">
              <UButton color="gray" variant="soft" label="sluit" :ui="{variant: { soft: 'dark:hover:bg-gray-800'}}" @click="isOpen = false" />
              <UButton color="success" variant="solid" label="verstuur"  type="submit" />
            </UButtonGroup>
          </div>
        </template>
      </IconCard>
    </UForm>
  </LazyUModal>
</template>

<script lang="ts" setup>

const platformOptions = ['facebook', 'linkedin', 'whatsapp', 'email'] as const;
type Platforms = typeof platformOptions;
type Platform = typeof platformOptions[number]
type PlatformItem = {
  key: Platform
  label: string
  icon: string
}


const props = withDefaults(
  defineProps<{
    title?: string
    platforms?: Platforms
    color: BaseColor

    mode?: 'results' | 'profile'
    useTooltip?: boolean
    url?: string

    magnification?: number;
    distance?: number;
    baseIconWidth?: number;

    emailDescription?: string
    emailPlaceholder?: string
    emailSubjectDescription?: string
    emailSubjectPlaceholder?: string
    messageDescription?: string
    messagePlaceholder?: string
  }>(), {
    platforms: () => ['facebook', 'linkedin', 'whatsapp', 'email'],
    color: 'primary',

    mode: 'profile',

    magnification: 10,
    distance: 60,
    baseIconWidth: 20,

    emailDescription: 'Aan wie wil je de mail sturen?',
    emailPlaceholder: 'voorbeeld@gmail.com',
    emailSubjectDescription: 'Kies een onderwerp voor je email',
    emailSubjectPlaceholder: 'Kies een onderwerp',
    messageDescription: 'Schrijf een persoonlijk bericht aan degene met wie je dit wilt delen. We voegen de link naar de huidige pagina automatisch toe.',
    messagePlaceholder: 'Voeg een bericht toe (optioneel)'
  }
)

const { socialShare } = useAppConfig().components

const dockComponent = computed(() => props.useTooltip ? resolveComponent('UTooltip') : 'div')


const list = computed(() => {
  const arr: PlatformItem[] = []

  if (props.platforms.includes('whatsapp')) {
    arr.push({
      key: 'whatsapp',
      label: 'Whatsapp',
      icon: 'i-mdi-whatsapp',
    })
  }
  if (props.platforms.includes('email')) {
    arr.push({
      key: 'email',
      label: 'email',
      icon: 'i-heroicons-envelope'
    })
  }
  if (props.platforms.includes('linkedin')) {
    arr.push({
      key: 'linkedin',
      label: 'LinkedIn',
      icon: 'i-mdi-linkedin',
    })
  }
  if (props.platforms.includes('facebook')) {
    arr.push({
      key: 'facebook',
      label: 'Facebook',
      icon: 'i-mdi-facebook',
    })
  }
  return arr
})

const isOpen = ref(false)
const currentDialogKey: Ref<null | Platform> = ref(null)

const currentItem = computed(() => {
  if (!currentDialogKey.value) return null
  return list.value.find(obj => obj.key === currentDialogKey.value) as PlatformItem
})

watch(isOpen, (newVal) => {
  if (!newVal) {
    currentDialogKey.value = null
    state.message = ''
  }
})

const shareUrl = computed(() => props.url || window?.location.href || '')
const openShareDialog = (platform: Platform) => {
  // Facebook and LInkedin dont allow to customize the sharing link beforehand. So just directly open the app
  if (platform === 'linkedin' || platform === 'facebook') {
    const { trackEvent } = useTracking()
    trackEvent('social_share', {
      event_category: 'engagement',
      event_label: platform,
      event_value: shareUrl.value
    })
    let url = platform === 'facebook' ? `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl.value)}` : `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl.value)}`
    window.open(url, "_blank");
  } else {
    isOpen.value = true
    currentDialogKey.value = platform
  }
  
}

const { grid } = useResponsive()

const state = reactive({
  emailAddress: '',
  subject: props.title || 'Iets voor jou?',
  message: ''
})

import type { FormError, FormSubmitEvent } from '#ui/types'

const validate = (s: typeof state): FormError[] => {
  const errors = []
  if (currentDialogKey.value === 'email' && !state.emailAddress) errors.push({ path: 'email', message: 'Voeg een emailadres toe' })
  else if (currentDialogKey.value === 'email' && !validateEmail(state.emailAddress)) errors.push({ path: 'email', message: 'Ongeldig emailadres' })

  if (currentDialogKey.value === 'email' && !state.subject) errors.push({ path: 'subject', message: 'Voeg een onderwerp toe' })
  return errors
}

const send = (event: FormSubmitEvent<any>) => {
  if (!currentItem.value) return
  if (currentDialogKey.value === 'email') {
    // construct url with mailto link and params
    let url = `mailto:${state.emailAddress}?subject=${encodeURIComponent(state.subject)}`
    let body = '&body='
    if (state.message) {
      body += encodeURIComponent(state.message)
      body += '%0A%0A'
    }

    body += encodeURIComponent(shareUrl.value)
    url += body
    window.open(url, "_blank");

  } else if (currentDialogKey.value === 'whatsapp') {
    let payload = ''
    if (state.message) {
      payload += state.message
      payload += '%0A%0A'
    }
    payload += encodeURIComponent(shareUrl.value)
    const url = `https://wa.me?text=${payload}`
    window.open(url, "_blank");
  }

  const { trackEvent } = useTracking()
  trackEvent('social_share', {
    event_category: 'engagement',
    event_label: currentDialogKey.value || '',
    event_value: shareUrl.value
  })
  

  nextTick(() => {
    isOpen.value = false
  })
}

</script>